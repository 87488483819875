// Component states
import states from './settings-button-modifier.state.js'
import workspaceStates from '@/components/parts/workspace/workspace.state.js'

// Components
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import MdiInformationOutline from 'vue-material-design-icons/InformationOutline.vue'
import MdiTextUnderline from 'vue-material-design-icons/FormatUnderline.vue'
import MdiTextItalic from 'vue-material-design-icons/FormatItalic.vue'
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
import MdiTextBold from 'vue-material-design-icons/FormatBold.vue'

// Services
import { mjmlJson, readingDirection } from '@/services/states/states.service'
import { ckTranslator } from '@/services/utils/utils.service'

// Libraries
import {
  get as _get,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-button-mj'

// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Vue@subComponents
const components = {
  AlignHorizontalContentModifier,
  MdiInformationOutline,
  SliderModifier,
  MdiTextBold,
  MdiTextItalic,
  custColorPicker,
  MdiTextUnderline
}
// Vue@subComponents

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspaceStates
  }
}
// Vue@data

// Methods
const methods = {

  // Func@changeTextStyle
  /**
   * Update text style
   * @param  {String} options.type  (css-key: font-weight/style...)
   * @param  {String} options.value (css-value: bold/italic/uderline...)
   */
  changeTextStyle ({ type, value }) {
    if (this.mjml.attributes[type] === value) {
      this.$set(this.mjml.attributes, type, 'initial')
      return
    }
    this.$set(this.mjml.attributes, type, value)
  },
  // Func@changeTextStyle

  /**
   * Generate quote for composed font to avoid
   * MJML issue as "Arial Black" becoming "Arial #000"
   * @param {String} fonts (Font list as "Arial Black, Arial-Black, Arial")
   */
  quoteComposedFontName (fonts) {
    return fonts.split(',').map(f => {
      const isComposedName = f.trim().split(' ').length > 1
      return isComposedName ? `'${f.trim()}'` : f.trim()
    }).join()
  },

  // Func@changeWidth
  /**
   * Update button width in percent
   * @param {Integer} value
   */
  changeWidth (value) {
    this.$set(this.mjml.attributes, 'width', `${value}${this.buttonUnitType}`)
  },
  // Func@changeWidth

  // Func@changeHeight
  /**
   * Update button height in pixel
   * @param {Integer} value
   */
  changeHeight (value) {
    if (this.mjml.attributes.height === '') return
    this.$set(this.mjml.attributes, 'height', `${value}px`)
  },
  // Func@changeHeight

  // Func@onLineHeightChanged
  /**
   * Update the `line-height` attribute with the new value
   * @param {Float} value
   */
  onLineHeightChanged (value) {
    this.mjml.attributes['line-height'] = `${value}`
  },
  // Func@onLineHeightChanged

  // Func@revertReadingDirection
  /**
   * Enable or disable RTL option
   * @param  {Boolean} isRtl (active or not)
   */
  revertReadingDirection (isRtl) {
    this.states.loading = true
    this.states.revertReadingDirection = isRtl
    this.states.editorConfig.language.content = isRtl ? 'ar' : 'en'
    if (_get(this.mjml.attributes, 'css-class')) this.mjml.attributes['css-class'] = isRtl ? 'is-rtl' : ''

    this.mjml.content = ckTranslator({
      entry: this.content,
      dest: 'MJML',
      options: { 
        isRtl, 
        onSwitch: true,
        isEnforcedLTR: readingDirection().get() === 'rtl'
      }
    })

    this.$nextTick(() => {
      this.states.loading = false
      this.updateEditorStyle()
    })
  },
  // Func@revertReadingDirection

  // Func@updateMaxWidthPixel
  /**
   * Update max width limite when button is defined in px
   */
  updateMaxWidthPixel () {
    const isColPixel = this.conf.parent.width.slice(-2) === 'px'
    const bodyAttr = _get(this.workspaceStates, 'template.children.0.attributes', {})
    const isBodyPixel = bodyAttr.width.slice(-2) === 'px'

    switch (true) {
      case isColPixel: {
        this.states.maxWidthPixel = this.conf.parent.width.split('px').shift()
        break
      }
      case isBodyPixel: {
        const bodyWidth = bodyAttr.width.split('px').shift()
        const parentWidth = this.conf.parent.width.split('%').shift()
        this.states.maxWidthPixel = Math.floor(bodyWidth * (parentWidth / 100))
        break
      }
      case !isBodyPixel: {
        this.states.maxWidthPixel = '300px'
        break
      }
    }
  },
  // Func@updateMaxWidthPixel

  // Func@updateEditorStyle
  /**
   * Update background color of ckEditor content
   * depending layers of colors
   */
  updateEditorStyle () {
    const bgColorLayers = mjmlJson().get({ context: 'bg-color-layers', id: this.mjml.id })
    const bgColorPriority = bgColorLayers.shift()
    const textColor = this.mjml.attributes.color
    const fontSize = this.mjml.attributes['font-size']
    const fontFamily = this.mjml.attributes['font-family']

    setTimeout(() => {
      if (!this.$refs.CKEditor) return

      const ckEditorContent = this.$refs.CKEditor
        .querySelector('.ck-editor__main')

      ckEditorContent.style['background-color'] = bgColorPriority.bgColor
      ckEditorContent.style.color = textColor
      ckEditorContent.style['font-size'] = fontSize
      ckEditorContent.style['font-family'] = fontFamily
    }, 300)
  },
  // Func@updateEditorStyle

  // Func@updateFontColor
  /**
   * Update font color on editor
   * @param  {String} val (hexa)
   */
  updateFontColor (val) {
    this.mjml.attributes.color = val
    this.updateEditorStyle()
  }
  // Func@updateFontColor
}

// Computed Methods
const computed = {

  // Func@isEnforcedLTR
  /**
   * Check if text is enforced left to right (in RTL default context)
   * @returns {Boolean}
   */
  isEnforcedLTR () {
    return this.mjml.content && this.mjml.content.includes('dir="ltr"')
  },
  // Func@isEnforcedLTR

  // Func@isRTL
  /**
   * Check reading direction
   * @return {Boolean}
   */
  isRTL () {
    if (!this.mjml.content) return readingDirection().get() === 'rtl'
    return !this.isEnforcedLTR && (this.mjml.content.includes('dir="rtl"') || readingDirection().get() === 'rtl')
  },
  // Func@isRTL

  // Func@paddingWidth
  /**
   * Calculate width used by padding
   * @return {[type]} [description]
   */
  paddingWidth () {
    const margins = this.mjml.attributes.padding.split(' ')
    return parseInt(margins[1]) + parseInt(margins[3])
  },
  // Func@paddingWidth

  // Func@lineHeight
  /**
   * Text line-height
   * @type {Object}
   */
  lineHeight () {
    return parseFloat(this.mjml.attributes['line-height'])
  },
  // Func@lineHeight

  // Func@buttonUnitType
  /**
   * Switch the unit type and update buttons current/max values
   * @type {Object}
   */
  buttonUnitType: {
    // getter
    get () {
      const widthSample = _get(this.mjml, 'attributes.width', '')
      const unitType = widthSample.slice(-2) === 'px' ? 'px' : '%'
      return unitType
    },
    // setter
    set (value) {
      let bodyWidth, parentWidth, currentPercent, currentPixel
      const switchedToPixel = value === 'px'
      const isColPixel = this.conf.parent.width.slice(-2) === 'px'
      const bodyAttr = _get(this.workspaceStates, 'template.children.0.attributes', {})
      const isBodyPixel = bodyAttr.width.slice(-2) === 'px'

      switch (true) {
        case (switchedToPixel && isColPixel): {
          this.updateMaxWidthPixel()
          parentWidth = this.conf.parent.width.split('px').shift()
          currentPercent = this.mjml.attributes.width.split('%').shift()
          this.mjml.attributes.width = `${Math.floor(parentWidth * (currentPercent / 100) - this.paddingWidth)}px`
          break
        }

        case switchedToPixel && isBodyPixel: {
          this.updateMaxWidthPixel()
          currentPercent = this.mjml.attributes.width.split('%').shift()
          this.mjml.attributes.width = `${Math.floor(this.states.maxWidthPixel * (currentPercent / 100) - this.paddingWidth)}px`
          break
        }

        case (switchedToPixel && !isBodyPixel): {
          this.updateMaxWidthPixel()
          this.mjml.attributes.width = `${100 - this.paddingWidth}px`
          break
        }

        case (!switchedToPixel && isColPixel): {
          parentWidth = parseInt(this.conf.parent.width.split('px').shift())
          currentPixel = parseInt(this.mjml.attributes.width.split('px').shift())
          this.mjml.attributes.width = `${(100 / parentWidth) * (currentPixel + this.paddingWidth)}%`
          break
        }

        case (!switchedToPixel && isBodyPixel): {
          bodyWidth = bodyAttr.width.split('px').shift()
          parentWidth = parseInt(this.conf.parent.width.split('%').shift())
          currentPixel = parseInt(this.mjml.attributes.width.split('px').shift())
          this.mjml.attributes.width = `${(100 / (bodyWidth * (parentWidth / 100)) * (currentPixel + this.paddingWidth))}%`
          break
        }

        case (!switchedToPixel && !isBodyPixel): {
          this.mjml.attributes.width = '100%'
          break
        }
      }
    }
  },
  // Func@buttonUnitType

   // Func@content
  /**
   * Content text without language format option
   * (managed directly via CKEDITOR config file)
   * @get {String} (Current text html)
   * @set (String) (Updated text html)
   */
  content: {
    // getter
    get () {
      return ckTranslator({
        entry: this.mjml.content,
        dest: 'CKE',
        options: {
          isRtl: this.states.revertReadingDirection,
          onSwitch: this.states.loading,
          isEnforcedLTR: readingDirection().get() === 'rtl'
        }
      })
    },
    // setter
    set (html) {
      this.mjml.content = ckTranslator({
        entry: html,
        dest: 'MJML',
        options: { 
          isRtl: this.states.revertReadingDirection,
          onSwitch: this.states.loading,
          isEnforcedLTR: readingDirection().get() === 'rtl'
        }
      })
    }
  },
  // Func@content

  // Func@autoHeight
  /**
   * With auto or manual
   */
  autoHeight: {
    // getter
    get () {
      return this.states.heightAuto
    },
    // setter
    set (boolean) {
      this.$set(this.states, 'heightAuto', boolean)

      if (boolean) {
        this.$set(this.states, 'tempHeight', this.mjml.attributes.height)
        this.$set(this.mjml.attributes, 'height', '')
        return
      }

      this.$set(this.mjml.attributes, 'height', this.states.tempHeight)
    }
  },
  // Func@autoHeight

  // Func@fontSize
  /**
   * Font size modifier
   */
  fontSize: {
    // getter
    get () {
      return parseInt(this.mjml.attributes['font-size'], 10)
    },
    // setter
    set (newValue) {
      this.$set(this.mjml.attributes, 'font-size', `${newValue}px`)
      this.updateEditorStyle()
    }
  },
  // Func@fontSize

  // Func@bgColor
  /**
   * Current bg colors
   * @return {String} (BG color of current mj-text)
   */
  bgColor () {
    return this.mjml.attributes['background-color']
  }
  // Func@bgColor
}

// Vue@watchTemplate
const watch = {
  bgColor: {
    handler () {
      this.updateEditorStyle()
    }
  }
}
// Vue@watchTemplate

// Func@updateMaxWidthPixel on create
/**
 * If value is px, define maxWidthPixel before component mounted
 */
function mounted () {
  if (this.buttonUnitType === 'px') this.updateMaxWidthPixel()
  if (this.isRTL) {
    setTimeout(() => {
      this.revertReadingDirection(true)
    })
  }
  this.$set(this.states, 'heightAuto', Boolean(!this.mjml.attributes.height))
  this.updateEditorStyle()
}
// Func@updateMaxWidthPixel

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  mounted,
  computed,
  components
}
